import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm'

function OnlineCV() {
  const { i18n } = useTranslation();
  const [markdown, setMarkdown] = useState('');

  useEffect(() => {
    const fetchMarkdown = async () => {
      try {
        const currentLanguage = i18n.language;
        const markdownContent = require(`./translations/${currentLanguage}_cv.md`);

        const response = await fetch(markdownContent);
        const text = await response.text();
        setMarkdown(text);
      } catch (error) {
        console.error('Failed to fetch the markdown content', error);
      }
    };

    fetchMarkdown();
  }, [i18n.language]);

  return (
    <div className='cv-container' style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
      <ReactMarkdown remarkPlugins={[[remarkGfm, {singleTilde: false}]]}>
        {markdown}
      </ReactMarkdown>
    </div>
  );
}

export default OnlineCV;
