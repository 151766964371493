import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
// import './HomePage.css';
import './PrivacyPolicy.css';

function HomePage() {
  const { i18n } = useTranslation();
  const [markdown, setMarkdown] = useState('');

  const currentLanguage = i18n.language;
  const markdownContent = require(`./translations/${currentLanguage}_home.md`);
  fetch(markdownContent).then((response) => response.text()).then((text) => setMarkdown(text));

  return (
    <div className="homepage">
      <ReactMarkdown>{markdown}</ReactMarkdown>
      <iframe width="100%" height="315" src="https://www.youtube.com/embed/3CVYzkQtv1o" title="ISTEC presentation" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen> </iframe>
    </div>
  );
}

export default HomePage;
