import React, { useEffect, useRef } from "react";
import './PyodideImageProcessing.css';

function PyodideImageProcessing() {
  const outputRef = useRef(null);
  const codeRef = useRef(null);
  const resultImageRef = useRef(null);

  const addToOutput = (s) => {
    outputRef.current.value += `${s}\n`;
    outputRef.current.scrollTop = outputRef.current.scrollHeight;
  };

  const handleImageSelect = (imageSrc) => {
    fetch(imageSrc)
      .then(response => response.blob())
      .then(blob => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64 = reader.result.split(',')[1];
          window.pyodide.globals.set('selected_image_base64', base64);
        };
        reader.readAsDataURL(blob);
      });
  };

  const evaluatePython = async () => {
    try {
      await window.pyodide.loadPackage(['numpy', 'matplotlib', 'scikit-image']);
      await window.pyodide.runPythonAsync(codeRef.current.textContent);
      let result_image_path = window.pyodide.globals.get('result_image_path');
      resultImageRef.current.src = result_image_path;
    } catch (e) {
      addToOutput(`Error executing Python code: ${e.message}`);
    }
  };

  useEffect(() => {
    const script = document.createElement('script');

    script.src = "https://cdn.jsdelivr.net/pyodide/v0.21.3/full/pyodide.js";
    script.async = true;

    document.body.appendChild(script);

    script.onload = async () => {
      outputRef.current.value = 'Initializing...\n';
      window.pyodide = await window.loadPyodide({ indexURL: "https://cdn.jsdelivr.net/pyodide/v0.21.3/full/" });
      outputRef.current.value += 'Ready!\n';
      try {
        const response = await fetch('/mammographyCancerDetection.py.txt');
        const pyCode = await response.text();
        codeRef.current.textContent = pyCode;
      } catch (e) {
        addToOutput(`Failed to load Python code: ${e.message}`);
      }
    };

    return () => {
      document.body.removeChild(script);
    };
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="simple-example">
      <h2>Mammography Cancer Detection Application</h2>
      <code ref={codeRef} rows='3'>
        # Python code will be loaded here
      </code>
      <div style={{ marginTop: '20px', textAlign: 'center' }}>
        <a href="https://github.com/ipekbayrak/MatlabBiomedicalImageProcessing" target="_blank" rel="noopener noreferrer">
          View the source code on GitHub
        </a>
      </div>
      <div>
        <input type="radio" name="image" value="mdb001.png" onClick={() => handleImageSelect('mdb001.png')} className="radio-input" />
        <img id='mdb001.png' src="mdb001.png" style={{ height: '100px' }} alt="mdb001" />
        <input type="radio" name="image" value="mdb002.png" onClick={() => handleImageSelect('mdb002.png')} className="radio-input" />
        <img id='mdb002.png' src="mdb002.png" style={{ height: '100px' }} alt="mdb002" />
        <input type="radio" name="image" value="mdb003.png" onClick={() => handleImageSelect('mdb003.png')} className="radio-input" />
        <img id='mdb003.png' src="mdb003.png" style={{ height: '100px' }} alt="mdb003" />
      </div>
      Output:
      <textarea ref={outputRef} id='output' style={{ width: '100%' }} rows='10' disabled></textarea>
      <button id='run' onClick={evaluatePython}>Run</button>
      <div>You can execute any Python code. Just select an image and click the button.</div>
      Result:
      <img 
      ref={resultImageRef} 
      id='result_image' 
      style={{ width: '640px', height: '480px' }} 
      alt="Result" 
      src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="
      />
    </div>
  );
}

export default PyodideImageProcessing;
