import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './Navbar';
import HomePage from './HomePage';
import OnlineCV from './OnlineCV';
import MyApplications from './MyApplications';
import ContactMe from './ContactMe';
import PrivacyPolicy from './PrivacyPolicy';
import Footer from './Footer';
import PyodideImageProcessing from './PyodideImageProcessing';

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <header className="App-header">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/online-cv" element={<OnlineCV />} />
            <Route path="/my-portfolio" element={<MyApplications />} />
            <Route path="/contact-me" element={<ContactMe />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/PyodideImageProcessing" element={<PyodideImageProcessing />} />
          </Routes>
        </header>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
