import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import { useTranslation } from 'react-i18next';

function Navbar() {
    const { i18n } = useTranslation();

    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang);
      };

  return (
    <nav className="navbar">
      <Link to="/" className="nav-link">About Me</Link>
      <Link to="/online-cv" className="nav-link">Online CV</Link>
      <Link to="/my-portfolio" className="nav-link">My Portfolio</Link>
      {//<Link to="/contact-me" className="nav-link">Contact Me</Link>
}
      <div style={{display: "none"}}>
      <button
        className="english"
        onClick={() => changeLanguage('en')}
      >
        
      </button>
      <button
        className="turkish"
        onClick={() => changeLanguage('tr')}
      >
        
      </button>
      </div>
    </nav>
  );
}

export default Navbar;
