import React from 'react';

function ContactMe() {
  return (
    <div>
      <h1>Contact Me</h1>
      <p>Hello, I'm Ipek Bayrak. Welcome to my personal website.</p>
      <p>Here you'll find information about my professional background, projects, and more.</p>
    </div>
  );
}

export default ContactMe;
