import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';

function MyApplications() {
    const { i18n } = useTranslation();
    const [markdown, setMarkdown] = useState('');

    const currentLanguage = i18n.language;
    const markdownContent = require(`./translations/${currentLanguage}_portfilo.md`);
    fetch(markdownContent).then((response) => response.text()).then((text) => setMarkdown(text));

  return (
    <div className='cv-container' style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
      <ReactMarkdown>{markdown}</ReactMarkdown>
    </div>
  );
}

export default MyApplications;
